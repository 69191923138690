<template>
  <v-row dense>
    <v-col cols="12">
      <c-input v-model="value.name" :label="$t('shared.name')" :rules="'required|max:100'" dir="auto" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'BaseForm',
  props: {
    value: {type: Object, default: () => ({})}
  }
}
</script>
