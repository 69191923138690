<script>
import BaseForm from './components/BaseForm.vue'
export default {
  name: 'EditForm',
  components: {
    BaseForm
  },
  data() {
    return {
      progressing: false,
      initializing: true,
      model: {
        name: null
      }
    }
  }
}
</script>
